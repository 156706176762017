<template>
  <a-card>
    <a-form-model :model="formData"
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol">
      <a-form-model-item label="网点名称"
                         prop="name" class="mb-1">{{formData.name}}
      </a-form-model-item>
      <a-form-model-item label="网点英文名"
                         prop="name_en" class="mb-1">{{formData.name_en}}
      </a-form-model-item>

      <a-form-model-item label="类型" class="mb-1">
        {{formData.type == 2 ? "配送站" : "门店"}}
      </a-form-model-item>

      <a-form-model-item label="地址" class="mb-1">{{formData.address}}</a-form-model-item>
      <a-form-model-item label="地址英文" class="mb-1">{{formData.address_en}}</a-form-model-item>

      <a-form-model-item label="营业时间" v-if="formData.type == 1" class="mb-1">
        {{formData.business_hours_start}} 至 {{formData.business_hours_end}}
      </a-form-model-item>

      <a-form-model-item label="门店电话" class="mb-1">
        {{formData.tel}}
      </a-form-model-item>

      <template>
        <a-form-model-item label="配送能力" class="mb-1">
          <span v-if="formData.is_delivery==2">本店可配送</span>
          <span v-else>本店不可配送</span>
          <!-- <a-checkbox :checked="formData.is_delivery==2" disabled>本店可配送</a-checkbox> -->
          <template v-if="formData.is_delivery==2">
            <span class="ml-10 mr-2">单日配送上限:</span>
            <a-input :value="formData.delivery_limit"
                     v-model="formData.delivery_limit"
                     placeholder="请输入配送上限" disabled
                     style="width: 100px;"></a-input>
          </template>
        </a-form-model-item>
        <!-- <a-form-model-item label="配送上限">
          {{formData.delivery_limit}}
        </a-form-model-item> -->

        <a-form-model-item label="配送时间" v-if="formData.is_delivery==2" class="mb-1">
          {{formData.delivery_hours_start}} 至 {{formData.delivery_hours_end}}
        </a-form-model-item>
      </template>

      <a-form-model-item label="负责人" prop="principal">
        <div style="display: flex; alignItems:center;">
          <a-input :value="formData.principal"
                   placeholder="请输入负责人名称"
                   style="width: 150px; marginRight: 25px"
                   v-model="formData.principal" />
          <span style="width: 75px;">联系电话:</span>
          <a-input :value="formData.principal_phone"
                   v-model="formData.principal_phone"
                   placeholder="请输入联系电话"
                   style="width: 150px; marginRight: 35px"></a-input>
          <a-button type="primary"
                    @click="hanlderPrincipalInfo">保存修改</a-button>
        </div>
      </a-form-model-item>

      <a-form-model-item label
                         :wrapper-col="{ span: 16, offset: 2 }">
        <div class="setting-class" >
          <h3>今日经营设置</h3>
          <a-form-model-item label="今日营业" v-if="formData.type == 1" class="mb-1">
            <div>
              <a-time-picker style="marginRight: 10px"
                             valueFormat="HH:mm"
                             format="HH:mm"
                             :default-open-value="moment('00:00', 'HH:mm')"
                             v-model="settingData.today_business_hours_start" />至
              <a-time-picker style="marginLeft: 10px"
                             valueFormat="HH:mm"
                             format="HH:mm"
                             :default-open-value="moment('00:00', 'HH:mm')"
                             v-model="settingData.today_business_hours_end" />
            </div>
          </a-form-model-item>

          <template v-if="formData.is_delivery==2">
            <a-form-model-item label="今日配送" class="mb-1">
              <div>
                <a-time-picker style="marginRight: 10px"
                               valueFormat="HH:mm"
                               format="HH:mm"
                               v-model="settingData.today_delivery_hours_start" />至
                <a-time-picker style="marginLeft: 10px"
                               valueFormat="HH:mm"
                               format="HH:mm"
                               :default-open-value="moment('00:00', 'HH:mm')"
                               v-model="settingData.today_delivery_hours_end" />
              </div>
            </a-form-model-item>
            
            <a-form-model-item label="最大运力:" class="mb-1">
              <a-select
                v-model="settingData.today_delivery_limit"
                style="width: 120px"
              >
                <a-select-option key="-1" :value="-1">未设置</a-select-option>
                <a-select-option
                  v-for="(item, index) in 200"
                  :key="index" :value="index + 1"
                >{{ index + 1 }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="是否接单">
              <a-select
                default-value="是"
                style="width: 120px"
                v-model="settingData.today_is_accept_distribution_order"
              >
                <a-select-option :value="0">未设置</a-select-option>
                <a-select-option :value="1">是</a-select-option>
                <a-select-option :value="2">否</a-select-option>
              </a-select>
            </a-form-model-item>
          </template>
          
          <div class="button-class">
            <a-button type="primary" style="margin-right: 15px">取消</a-button>
            <a-button type="primary" @click="handlerTodayConfig">修改</a-button>
          </div>
        </div>
      </a-form-model-item>
    </a-form-model>
  </a-card>
</template>

<script>
import moment from "moment"
import { requiredRule } from "@/hooks/use-form-rules"
// import { getCityList } from "@/api/outlets"
// import { getRegionList } from "@/api/util"
import { configDetail, modifyConfig, modifyTodayConfig } from "@/api/shop"
export default {
  data () {
    return {
      requiredRule: requiredRule,
      shopType: true,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      isChecked: 0,
      formData: {
        name: "",
        // 是否配送 （1-否，2-是）
        is_delivery: 1,
        // 网点类型 （1-门店，2-配送站)
        type: 2,
        // 配送上限
        delivery_limit: "",
        address: "",
        business_hours_start: "",
        business_hours_end: "",
        delivery_hours_start: "",
        delivery_hours_end: "",
        // outlets_city_adcode: null,
        area_adcode: null,
        street_adcode: null,
        tel: "",
        latitude: "",
        longitude: "",
        principal: "",
        principal_phone: "",
      },
      settingData: {
        today_business_hours_start: "",
        today_business_hours_end: "",
        today_delivery_hours_start: "",
        today_delivery_hours_end: "",
        // 今日-是否接单 （0-没有设置，1-是，2-否）
        today_is_accept_distribution_order: "",
        today_delivery_limit: -1
      },
      // cityList: [],
      areaList: [],
      streetList: [],
      priceAreaList: [],
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    moment,
    async initData () {
      const { code, data } = await configDetail()
      if (code == 0) {
        this.formData = data
        // 是否配送 （1-否，2-是）
        if (this.formData.is_delivery == 2) {
          this.isChecked = true
        } else {
          this.isChecked = false
        }
        this.settingData = data
        // this.getCityListData()
        // this.handlerCityChange(this.formData.outlets_city_adcode)
        // this.handlerAreaChange(this.formData.area_adcode)
      }
    },
    // 修改联系人 联系人电话
    hanlderPrincipalInfo () {
      let that = this
      this.$confirm({
        title: "确认要修改联系人信息么?",
        async onOk() {
          const { code, message } = await modifyConfig({
            principal: that.formData.principal,
            principal_phone: that.formData.principal_phone,
          })
          if (code == 0) {
            that.$message.success(message)
            that.$forceUpdate()
          } else {
            that.$message.error(message)
          }
        },
        onCancel () {
          console.log("Cancel")
        },
      })
    },
    // 修改今日营业设置
    handlerTodayConfig() {
      let that = this
      this.$confirm({
        title: "确认要修改今日营业设置么?",
        async onOk () {
          const { code, message } = await modifyTodayConfig(that.settingData)
          if (code == 0) {
            that.$message.success(message)
            that.$forceUpdate()
          } else {
            that.$message.error(message)
          }
        },
        onCancel () {
          console.log("Cancel")
        },
      })
    },
    // 城市列表
    // async getCityListData () {
    //   const { code, data } = await getCityList()
    //   if (code == 0) {
    //     this.cityList = data.list
    //   }
    // },

    // 选择城市列表获取区/县数据
    // async handlerCityChange (e) {
    //   const { code, data } = await getRegionList({ type: 3, parent_adcode: e })
    //   if (code == 0) {
    //     this.areaList = data.list
    //   }
    // },
    // 选择区/县列表获取街道数据
    // async handlerAreaChange (e) {
    //   const { code, data } = await getRegionList({ type: 4, parent_adcode: e })
    //   if (code == 0) {
    //     this.streetList = data.list
    //   }
    // },
  },
}
</script>

<style lang="less" scoped>
.setting-class {
  padding: 20px;
  margin-left: 30px;
  background: #f2f2f2;
}
.button-class {
  text-align: center;
}
</style>
