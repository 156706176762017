export const requiredRule = [{required: true, message: '该项为必填项', trigger: 'blur'}]

export const phoneRule = [
  {pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/, message: '请输入正确的手机号', trigger: 'blur'},
]



/* 手机号码验证*/
export function validatePhone(str) {
  const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
  return reg.test(str)
}
